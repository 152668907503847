@import "./src/assets/scss/main.scss";

















    .OperatorStatusMenu{
        display: flex;
        a{
            color:#3F2A2C;
            text-decoration: none;
            font-family: 'Montserrat';
font-style: normal;
display: flex;
justify-content: center;
white-space: nowrap;
align-items: center;
font-weight: 600;
font-size: 16px;
width: 152px;
height: 50px;
border: 1px solid #3F2A2C;
border-radius: 9px;
        }
        .router-link-active{
            background: #3F2A2C;
            color:#fff;
            &:hover{
                color:#fff;
            }
        }
    }
